import { lazy, Suspense } from "react";
import { Routes as Switcher, Route, Navigate } from "react-router-dom";
import Loader from "../components/Loader";
import Login from "../pages/Login/Login";
import UseAuth from "../hooks/UseAuth";
import FormIndex from "../pages/FaaliyetAlanlari/FormIndexTR";
import HomeMain from "../pages/Home/HomeMain";
import FaaliyetAlanlariMain from "../pages/FaaliyetAlanlari/FaaliyetAlanlariMain";
import FormIndexEn from "../pages/FaaliyetAlanlari/FormIndexEN";
import ReferencesMain from "../pages/References/ReferencesMain";
import FormIndexRefTR from "../pages/References/FormIndexRefTR";
import FormIndexRefEN from "../pages/References/FormIndexRefEN";
import DevamEdenProjelerMain from "../pages/DevamEdenProjeler/DevamEdenProjelerMain";
import FormIndexDevamEden from "../pages/DevamEdenProjeler/FormIndexDevamEden";
import TamamlananProjeler from "../pages/TamamlananProjeler/TamamlananProjeler";
import FormIndexTamamlanan from "../pages/TamamlananProjeler/FormIndexTamamlanan";
import MakinaParkiMain from "../pages/MakinaParki/MakinaParkiMain";
import FormIndexMakinaTr from "../pages/MakinaParki/FormIndexMakinaTr";
import FormIndexMakinaEn from "../pages/MakinaParki/FormIndexMakinaEn";

const NotFound = lazy(() => import("../pages/NotFound"));

function Routes() {
  const routeConfig = [
    {
      path: "/login",
      component: Login,
    },
    {
      path: "/home",
      component: HomeMain,
    },
    {
      path: "/faaliyet-alanlari",
      component: FaaliyetAlanlariMain,
    },
    {
      path: "not-found",
      component: NotFound,
    },
    {
      path:"form-faaliyet-alanlari",
      component: FormIndex
    },
    {
      path:"form-faaliyet-alanlari-en",
      component: FormIndexEn
    },
    {
      path:"referanslar",
      component: ReferencesMain
    },
    {
      path:"form-referans",
      component: FormIndexRefTR
    },
    {
      path:"form-referans-en",
      component: FormIndexRefEN
    }
    , {
      path:"devam-eden-projeler",
      component: DevamEdenProjelerMain
    },
    {
      path:"form-devam-eden-projeler",
      component: FormIndexDevamEden
    }
    , {
      path:"tamamlanan-projeler",
      component: TamamlananProjeler
    },
    {
      path:"form-tamamlanan-projeler",
      component: FormIndexTamamlanan
    }
    , {
      path:"makina-parki",
      component: MakinaParkiMain
    }
    , {
      path:"form-makina-parki-tr",
      component: FormIndexMakinaTr
    }
    , {
      path:"form-makina-parki-en",
      component: FormIndexMakinaEn
    }
  ];
  return (
  <Switcher>
      {routeConfig.map((route) => (
        <Route
          key={route.path}
          exact
          path={route.path}
          element={
            <Suspense fallback={<Loader />}>
              <route.component />
            </Suspense>
          }
        />
      ))}
    
    </Switcher>

  );
}

export default Routes;
