import React, { useState, useEffect } from "react";
import { Container, Card, CardTitle, CardBody, Row, Button, Table, Input } from "reactstrap";
import { useNavigate } from "react-router-dom";
import alertify from "alertifyjs";

import Layout from "../../Layout";

import Footer from "../../Layout/Footer";

import DevamEdenProjeler from "./DevamEdenProjeler";



const DevamEdenProjelerMain = () => {


  return (
    <Layout>
      <main className="home">
        <Container>
          <div className="h1">Devam Eden Projeler</div>
        </Container>
<DevamEdenProjeler/>
       <Footer/>
      </main>
    </Layout>
  );
};

export default DevamEdenProjelerMain;
