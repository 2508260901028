import Profile from "../../components/Profile";
import logo from "../../assets/logo.png"
function Header() {
  return (
    <header>
      <div
        className="nav-box d-none d-xl-grid"
        onClick={() => window.scrollTo({ top: 0, behavior: "smooth" })}
      >
        <img src={logo}></img>
        <div className="osi-panel">
          <b>osi</b>&nbsp;panel
        </div>
      </div>
      <Profile />
    </header>
  );
}

export default Header;
