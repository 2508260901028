import React, { useState, useEffect } from "react";
import { Container, Card, CardTitle, CardBody, Row, Button, Table, Input } from "reactstrap";
import { useNavigate } from "react-router-dom";
import alertify from "alertifyjs";
import { AiFillDelete } from "react-icons/ai";
import { BiEdit } from "react-icons/bi";
import { FormApi } from "../../api/form_api";
import ReactHtmlParser from "react-html-parser";
import Layout from "../../Layout";
import Paginator from "../../Paginator";
const endpoint = "makinaParkiEn";

const MakinaParkiEn = () => {
  const formApi = new FormApi(endpoint);
  const navigate = useNavigate();
  const [products, setProducts] = useState([]);
  const [productsAll, setProductsAll] = useState([]);
  const [tempProducts, setTempProducts] = useState([]);
  const [modal, setModal] = useState(false);
  const toggle = () => setModal(!modal);
  const [search, setSearch] = useState("");

  const goEditProduct = (product) => {
    navigate("/form-makina-parki-en", { state: { product } });
  };

  const deleteProduct = async (e, product) => {
    e.preventDefault();

    alertify.confirm(
      "Silme İşlemi",
      `${product._id} nolu makina parkı(en) silmek istediğinizden emin misiniz?`,
      async function () {
        try {
          const obj = { _id: product._id };
          const result = await formApi.deletePage(obj);
          if (result) {
            alertify.success("makina parkı(en) silindi..", 2.5);
            setTimeout(() => {
              window.location.reload();
            }, 1500);
          }
        } catch (e) {
          alertify.error("makina parkı(en) silinemedi..", 2.5);
        }
      },
      function () {
        alertify.error("İşlemden vazgeçildi...", 20);
      }
    );
  };

  const loadPage = (products) => {
    return products.map((product) => (
      <tr className="align-middle text-center" key={product._id}>
        <td>{product.baslik}</td>
        <td className="align-middle">
  {product.icerik ? product.icerik.map((item, index) => (
    <div key={index}>
      <b>{index + 1}. Makina</b>
      <div> Marka: {item.marka}</div>
      <div>Tipi: {item.tipi}</div>
      <div>Modeli: {item.model}</div>

      <hr/>
    </div>
  )) : "İçerik bulunamadı."}
</td>



        <td className="option">
          <BiEdit onClick={(e) => goEditProduct(product)} />
          <AiFillDelete onClick={(e) => deleteProduct(e, product)} />
        </td>
      </tr>
    ));
  };

  const onSearchHandler = (e) => {
    e.preventDefault();

    const filteredProducts = productsAll.filter((product) =>
      product.baslik.toLowerCase().includes(search.toLowerCase())
    );

    setProducts(filteredProducts);
    setTempProducts(loadPage(filteredProducts));
  };

  useEffect(() => {
    const fetchData = async () => {
      const res = await formApi.getPage();
      setProducts(res);
      setTempProducts(loadPage(res));
      setProductsAll(res);
    };
    fetchData();
  }, []);

  return (
    <Layout>
      <main className="home">
      

        <section id="section-one">
          <Container>
            <Row>
              <Card>
                <CardBody>
                  <CardTitle>
                    <b>Makina Parkı</b> - EN
                  </CardTitle>
                  <br />

                  <div className="p-3">
                    <div className="d-lg-flex d-md-flex  justify-content-between p-3">
                      <Button className="bg-black" onClick={() => navigate("/form-makina-parki-tr")}>
                        Makina Parkı Ekle (en)
                      </Button>

                      <form className="d-flex" onSubmit={onSearchHandler}>
                        <Input
                          type="search"
                          name="search"
                          id="exampleSearch"
                          placeholder="Başlığa göre arayın..."
                          value={search}
                          style={{ borderTopRightRadius: 0, borderBottomRightRadius: 0 }}
                          onChange={(e) => setSearch(e.target.value)}
                        />
                        <Button
                          className="bg-black"
                          type="submit"
                          style={{ borderTopLeftRadius: 0, borderBottomLeftRadius: 0 }}
                        >
                          Ara
                          <i className="fa fa-search"></i>
                        </Button>
                      </form>
                    </div>
                    <div className="container-fluid">
                      <div className="row">
                        <div className="table-scroll col-12">
                          <Table striped bordered hover>
                            <thead>
                              <tr className="text-bold text-center">
                                
                                <th>Makina Parkı Adı</th>
                                <th>Makina Pakı İçeriği</th>
                                <th>Seçenekler</th>
                              </tr>
                            </thead>
                            <tbody>
                              {tempProducts.length === 0 ? (
                                <p className="text-danger p-2">Makina parkı(en) bulunamadı</p>
                              ) : (
                                tempProducts.slice(0, 10)
                              )}
                            </tbody>
                          </Table>
                          {products ? (
                            <Paginator
                              len={products.length}
                              data={products}
                              setTempProducts={setTempProducts}
                              loadPage={loadPage}
                            />
                          ) : null}
                        </div>
                      </div>
                    </div>
                  </div>
                </CardBody>
              </Card>
            </Row>
          </Container>
        </section>
      </main>
    </Layout>
  );
};

export default MakinaParkiEn;
