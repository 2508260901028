import { useState, useEffect } from "react";
import { Form, FormGroup, Label, Input, Button } from "reactstrap";
import { Container, Card, CardTitle, CardBody, Row, Table } from "reactstrap";
import Layout from "../../Layout";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import alertify from "alertifyjs";
import { useLocation } from "react-router-dom";
import { FormApi } from "../../api/form_api";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Slider from "../../Slider";

const dataSources = [
  {
    id: 1,
    dataURL: "https://picsum.photos/seed/1/600",
  },
  {
    id: 2,
    dataURL: "https://picsum.photos/seed/2/600",
  },
  {
    id: 3,
    dataURL: "https://picsum.photos/seed/3/600",
  },
  {
    id: 4,
    dataURL: "https://picsum.photos/seed/4/600",
  },
  {
    id: 5,
    dataURL: "https://picsum.photos/seed/5/600",
  },
  {
    id: 6,
    dataURL: "https://picsum.photos/seed/6/600",
  },
];
const endpoint = "finished";
function FormIndexTamamlanan() {
  const formApi = new FormApi(endpoint);
  const location = useLocation();

  const [form, setForm] = useState({
    finishedNo: "",
    name: "",
    title: "",
    img:"",
    start:"",
    finish:""
 
  });

  const [validation, setValidation] = useState({
    finishedNo: "",
    name: "",
    title: "",
    img:"",
    start:"",
    finish:""
  });
  useEffect(() => {
    if (location.state)
      Object.entries(location.state.product).forEach(([key, value]) => {
        onChangeHandler(key, value);
        setValidation((prevState) => ({ ...prevState}));
      });
  }, []);
  const validator = (key, value) => {
    if (value.length === 0 || value === "--" || value === "F") {
      setValidation((prevState) => ({ ...prevState, [key]: "F" }));
      return true;
    } else setValidation((prevState) => ({ ...prevState, [key]: "T" }));
  };

  const onChangeHandler = (key, value) => {
    setForm((prevState) => ({ ...prevState, [key]: value }));
    validator(key, value);
  };

  const onSubmitHandler = async (e) => {
    e.preventDefault();

    let isError = false;
    Object.entries(validation).forEach(([key, value]) => {
      if (validator(key, value)) isError = true;
    });

    let formData = new FormData();
    formData.append("name", form.name);
    formData.append("finishedNo", form.finishedNo);
    formData.append("title", form.title);
    formData.append("start", form.start);
    formData.append("finish", form.finish);
    formData.append("img", form.img);

    try {
      const rst = await formApi.addPage(formData);
      toast.success(
        "Devam eden proje eklendi..",
        ((window.location.href = "/tamamlanan-projeler"), 5000),
        2.5
      );
    } catch (e) {
      toast.error("Tamamlanan proje eklenemedi..", 2.5);
    }
  };
  const onUpdateHandler = async (e) => {
    e.preventDefault();
    let isError = false;
    Object.entries(validation).forEach(([key, value]) => {
      if (validator(key, value)) isError = true;
    });

    if (isError) {
      toast.error("Düzenlemeler yapılamadı..", 2.5);

      return;
    } else {
      let formData = new FormData();
      formData.append("_id", form._id);
      formData.append("name", form.name);
    formData.append("finishedNo", form.finishedNo);
    formData.append("title", form.title);
    formData.append("img", form.img);
    formData.append("start", form.start);
    formData.append("finish", form.finish);
      try {
        const rst = await formApi.updatePage(formData);
        toast.success(
          "Düzenlemeler yapıldı..",
          ((window.location.href = "/tamamlanan-projeler"), 1000),
          2.5
        );
      } catch (e) {
        toast.error("Düzenlemeler yapılamadı..", 1000);
      }
    }
  };

  useEffect(() => {
    if (location.state)
      Object.entries(location.state.product).forEach(([key, value]) => {
        onChangeHandler(key, value);
        setValidation((prevState) => ({ ...prevState, img: "T" }));
      });
  }, []);

  const imageUpload = (data) => {
    setForm((prevState) => ({ ...prevState, ["img"]: data }));
  };

  return (
    <Layout>
        <ToastContainer/>
      <main className="home">
        <Container>
          <div className="h1">Tamamlanan Projeler</div>
        </Container>

        <section id="section-one">
          <Container>
            <Row>
              <Card>
                <CardBody>
                 
                  <br />
                  <div className="container mt-3 pb-5">
      <h4>Tamamlanan Proje Ekleme Paneli</h4>
      <hr />

      <Form onSubmit={onSubmitHandler}>
        

      
      <Label>
          <b>Fotoğraflar</b>
        </Label>

        <Slider imageUpload={imageUpload} data={dataSources} />

      
        <br />
        <div className="row col-12">
          <div className="col-12">
            <FormGroup>
              <Label for="finishedNo">
                <b>Tamamlanan Proje Numarası</b>
              </Label>
              <Input
                id="finishedNo"
                name="finishedNo"
                placeholder="Lütfen tamamlanan proje numarasını giriniz..."
                type="number"
                valid={validation.finishedNo === "T"}
                invalid={validation.finishedNo === "F"}
                onChange={(e) => onChangeHandler(e.target.name, e.target.value)}
                value={form.finishedNo}
                min={0}
              />
         
            </FormGroup>
            <FormGroup>
              <Label for="name">
                <b>Tamamlanan projenin bulunduğu şehir</b>
              </Label>
              <Input
                id="name"
                name="name"
                placeholder="Lütfen tamamlanan projenin bulunduğu şehri giriniz..."
                type="text"
                onChange={(e) => onChangeHandler(e.target.name, e.target.value)}
                value={form.name}
                valid={validation.name === "T"}
                invalid={validation.name === "F"}
              />
           
            </FormGroup>
            <FormGroup>
              <Label for="start">
                <b>Tamamlanan projenin başlangıç tarihi</b>
              </Label>
              <Input
                id="start"
                name="start"
                placeholder="Lütfen tamamlanan projenin başlangıç tarihini giriniz..."
                type="text"
                onChange={(e) => onChangeHandler(e.target.name, e.target.value)}
                value={form.start}
                valid={validation.start === "T"}
                invalid={validation.start === "F"}
              />
           
            </FormGroup>
            <FormGroup>
              <Label for="finish">
                <b>Tamamlanan projenin bitiş tarihi</b>
              </Label>
              <Input
                id="finish"
                name="finish"
                placeholder="Lütfen tamamlanan projenin bitiş tarihini giriniz..."
                type="text"
                onChange={(e) => onChangeHandler(e.target.name, e.target.value)}
                value={form.finish}
                valid={validation.finish === "T"}
                invalid={validation.finish === "F"}
              />
           
            </FormGroup>
            <FormGroup>
              <Label for="title">
                <b>Tamamlanan projenin açıklaması</b>
              </Label>
              <Input
                id="title"
                name="title"
                placeholder="Lütfen tamamlanan projenin açıklaması giriniz..."
                type="text"
                onChange={(e) => onChangeHandler(e.target.name, e.target.value)}
                value={form.title}
                valid={validation.title === "T"}
                invalid={validation.title === "F"}
              />
           
            </FormGroup>
          </div>
        </div>

       
        {validation.icerik === "F" && (
          <div className="val-message">Bu alan boş bırakılamaz.</div>
        )}

        <br />
        <Button color="dark" type="submit" onClick={(e) => onSubmitHandler(e)}>
          Ekle
        </Button>
        {"  "}
        <Button
          type="submit"
          style={{
            background: "white",
            color: "black",
          }}
          onClick={(e) => onUpdateHandler(e)}
        >
          Güncelle
        </Button>
      </Form>
    </div>
                
                </CardBody>
              </Card>
            </Row>
          </Container>
        </section>
      </main>
    </Layout>
  );
}

export default FormIndexTamamlanan;
