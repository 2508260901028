import React, { useState, useEffect } from "react";
import { Container, Card, CardTitle, CardBody, Row, Button, Table, Input } from "reactstrap";
import { useNavigate } from "react-router-dom";
import alertify from "alertifyjs";
import { AiFillDelete } from "react-icons/ai";
import { BiEdit } from "react-icons/bi";
import { FormApi } from "../../api/form_api";
import ReactHtmlParser from "react-html-parser";
import Layout from "../../Layout";
import FaaliyetTR from "./FaaliyetTR";
import FaaliyetEN from "./FaaliyetEN";
import Footer from "../../Layout/Footer";



const FaaliyetAlanlariMain = () => {


  return (
    <Layout>
      <main className="home">
        <Container>
          <div className="h1">Faaliyet Alanları</div>
        </Container>

       <FaaliyetTR/>
       <FaaliyetEN/>
       <Footer/>
      </main>
    </Layout>
  );
};

export default FaaliyetAlanlariMain;
