import { useState, useEffect } from "react";
import { Form, FormGroup, Label, Input, Button } from "reactstrap";
import { Container, Card, CardTitle, CardBody, Row, Table } from "reactstrap";
import Layout from "../../Layout";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import alertify from "alertifyjs";
import { useLocation } from "react-router-dom";
import { FormApi } from "../../api/form_api";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const endpoint = "makinaParkiTr";

function FormIndexMakinaTr() {
  const formApi = new FormApi(endpoint);
  const location = useLocation();

  
  const [form, setForm] = useState({
    _id: "",
    icerik: [], // Boş bir dizi olarak başlatıldı
    baslik: "",
  });
  
  const [validation, setValidation] = useState({
    _id: "",
    icerik: [],
    baslik: "",

  });
  useEffect(() => {
    if (location.state) {
      const product = location.state.product;
      const icerikArray = Array.isArray(product.icerik) ? product.icerik : [product.icerik];
      setForm(prevState => ({
        ...prevState,
        _id: product._id,
        baslik: product.baslik,
        icerik: icerikArray
      }));
    }
  }, []);
  
  useEffect(() => {
    if (location.state)
      Object.entries(location.state.product).forEach(([key, value]) => {
        onChangeHandler(key, value);
        setValidation((prevState) => ({ ...prevState}));
      });
  }, []);
  const validator = (key, value) => {
    if (value.length === 0 || value === "--" || value === "F") {
      setValidation((prevState) => ({ ...prevState, [key]: "F" }));
      return true;
    } else setValidation((prevState) => ({ ...prevState, [key]: "T" }));
  };

  const onChangeHandler = (key, value) => {
    setForm((prevState) => ({ ...prevState, [key]: value }));
    validator(key, value);
  };

  const onSubmitHandler = async (e) => {
    e.preventDefault();

    let isError = false;
    Object.entries(validation).forEach(([key, value]) => {
      if (validator(key, value)) isError = true;
    });

    let formData = new FormData();
    formData.append("baslik", form.baslik);
    formData.append("_id", form._id);
    formData.append("icerik", JSON.stringify(form.icerik));


    try {
      const rst = await formApi.addPage(formData);
      toast.success(
        "Makina parkı(tr) eklendi..",
        ((window.location.href = "/makina-parki"), 5000),
        2.5
      );
    } catch (e) {
      toast.error("Makina parkı(tr) eklenemedi..", 2.5);
    }
  };
  const onUpdateHandler = async (e) => {
    e.preventDefault();
    let isError = false;
    Object.entries(validation).forEach(([key, value]) => {
      if (validator(key, value)) isError = true;
    });

    if (isError) {
      toast.error("Düzenlemeler yapılamadı..", 2.5);

      return;
    } else {
      let formData = new FormData();
      formData.append("_id", form._id);
      formData.append("baslik", form.baslik);
      formData.append("_id", form._id);
      formData.append("icerik", JSON.stringify(form.icerik));
      

      try {
        const rst = await formApi.updatePage(formData);
        toast.success(
          "Düzenlemeler yapıldı..",
          ((window.location.href = "/makina-parki"), 1000),
          2.5
        );
      } catch (e) {
        toast.error("Düzenlemeler yapılamadı..", 1000);
      }
    }
  };
  const addMachine = () => {
    const newMachine = { marka: '', tipi: '', model: '' };
    setForm(prevState => ({
      ...prevState,
      icerik: [...form.icerik, newMachine]
    }));
  };
  const onContentChange = (index, key, value) => {
    const newIcerik = [...form.icerik];
    newIcerik[index][key] = value;
    setForm(prevState => ({
      ...prevState,
      icerik: newIcerik
    }));
  };
  

  return (
    <Layout>
        <ToastContainer/>
      <main className="home">
        <Container>
          <div className="h1">Makina Parkı (tr)</div>
        </Container>

        <section id="section-one">
          <Container>
            <Row>
              <Card>
                <CardBody>
                  <CardTitle>
                    <b>Makina Parkı</b> - TR
                  </CardTitle>
                  <br />
                  <div className="container mt-3 pb-5">
      <h4>Makina Parkı Ekleme Paneli (tr)</h4>
      <hr />

      <Form onSubmit={onSubmitHandler}>
        

      

        <br />
        <div className="row col-12">
          <div className="col-12">
            <FormGroup>
            
         
            </FormGroup>
            <FormGroup>
              <Label for="baslik">
                <b>Makina Parkı Adı</b>
              </Label>
              <Input
                id="baslik"
                name="baslik"
                placeholder="Lütfen makina parkı adını giriniz..."
                type="text"
                onChange={(e) => onChangeHandler(e.target.name, e.target.value)}
                value={form.baslik}
                valid={validation.baslik === "T"}
                invalid={validation.baslik === "F"}
              />
           
            </FormGroup>
          </div>
        </div>

        <Label for="icerik">
  <b>Makina İçerikleri(tr)</b>
</Label>
{/* Makina içeriklerini girebilecekleri alan */}
{form.icerik.map((item, index) => (
  <div key={index} className="row col-12">
    <div className="col-4">
      <FormGroup>
        <Input
          placeholder="Marka"
          value={item.marka}
          onChange={(e) => onContentChange(index, 'marka', e.target.value)}
        />
      </FormGroup>
    </div>
    <div className="col-4">
      <FormGroup>
        <Input
          placeholder="Tip"
          value={item.tipi}
          onChange={(e) => onContentChange(index, 'tipi', e.target.value)}
        />
      </FormGroup>
    </div>
    <div className="col-4">
      <FormGroup>
        <Input
          placeholder="Model"
          value={item.model}
          onChange={(e) => onContentChange(index, 'model', e.target.value)}
        />
      </FormGroup>
    </div>
  </div>
))}
<br/>
<Button color="primary" onClick={addMachine}>Yeni Makina Ekle</Button>
{validation.icerik === "F" && (
  <div className="val-message">Bu alan boş bırakılamaz.</div>
)}

<br/>
        <br />
        <Button color="dark" type="submit" onClick={(e) => onSubmitHandler(e)}>
          Ekle
        </Button>
        {"  "}
        <Button
          type="submit"
          style={{
            background: "white",
            color: "black",
          }}
          onClick={(e) => onUpdateHandler(e)}
        >
          Güncelle
        </Button>
      </Form>
    </div>
                
                </CardBody>
              </Card>
            </Row>
          </Container>
        </section>
      </main>
    </Layout>
  );
}

export default FormIndexMakinaTr;
