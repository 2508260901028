import React, { useState, useEffect } from "react";
import { Container, Card, CardTitle, CardBody, Row, Button, Table, Input } from "reactstrap";
import { useNavigate } from "react-router-dom";
import alertify from "alertifyjs";
import { AiFillDelete } from "react-icons/ai";
import { BiEdit } from "react-icons/bi";
import { FormApi } from "../../api/form_api";
import ReactHtmlParser from "react-html-parser";
import Layout from "../../Layout";
import Paginator from "../../Paginator";

const endpoint = "finished";

const TamamlananProjeler = () => {
  const formApi = new FormApi(endpoint);
  const navigate = useNavigate();
  const [products, setProducts] = useState([]);
  const [productsAll, setProductsAll] = useState([]);
  const [tempProducts, setTempProducts] = useState([]);
  const [modal, setModal] = useState(false);
  const toggle = () => setModal(!modal);
  const [search, setSearch] = useState("");

  const goEditProduct = (product) => {
    navigate("/form-tamamlanan-projeler", { state: { product } });
  };

  const deleteProduct = async (e, product) => {
    e.preventDefault();

    alertify.confirm(
      "Silme İşlemi",
      `${product.finishedNo} nolu tamamlanan projeyi silmek istediğinizden emin misiniz?`,
      async function () {
        try {
          const obj = { _id: product._id, img: product.img };
          const result = await formApi.deleteImage(obj);
          if (result) {
            alertify.success("Tamamlanan proje silindi..", 2.5);
            setTimeout(() => {
              window.location.reload();
            }, 1500);
          }
        } catch (e) {
          alertify.error("Tamamlanan proje silinemedi..", 2.5);
        }
      },
      function () {
        alertify.error("İşlemden vazgeçildi...", 20);
      }
    );
  };

  const loadPage = (products) => {
    return products.map((product) => (
      <tr className="align-middle text-center" key={product.finishedNo}>
        <td>{product.finishedNo}</td>
        <td className="">
          {" "}
          <img
            src={`https://server.ozciftay.com.tr/uploads/${product.img}`}
            height={50}
          />
        </td>
        <td>{product.name}</td>
        <td>{product.start}</td>
        <td>{product.finish}</td>
        <td className="align-middle">
  {product.title ? ReactHtmlParser(product.title.substring(0, 250)) : "İçerik bulunamadı."}
</td>

        <td className="option">
          <BiEdit onClick={(e) => goEditProduct(product)} />
          <AiFillDelete onClick={(e) => deleteProduct(e, product)} />
        </td>
      </tr>
    ));
  };

  const onSearchHandler = (e) => {
    e.preventDefault();

    const filteredProducts = productsAll.filter((product) =>
      product.baslik.toLowerCase().includes(search.toLowerCase())
    );

    setProducts(filteredProducts);
    setTempProducts(loadPage(filteredProducts));
  };

  useEffect(() => {
    const fetchData = async () => {
      const res = await formApi.getPage();
      setProducts(res);
      setTempProducts(loadPage(res));
      setProductsAll(res);
    };
    fetchData();
  }, []);

  return (
    <Layout>
      <main className="home">
      

        <section id="section-one">
          <Container>
            <Row>
              <Card>
                <CardBody>
                  <CardTitle>
                    <b>Tamamlanan Projeler</b>
                  </CardTitle>
                  <br />

                  <div className="p-3">
                    <div className="d-lg-flex d-md-flex  justify-content-between p-3">
                      <Button className="bg-black" onClick={() => navigate("/form-tamamlanan-projeler")}>
                        Tamamlanan Proje Ekle
                      </Button>

                      <form className="d-flex" onSubmit={onSearchHandler}>
                        <Input
                          type="search"
                          name="search"
                          id="exampleSearch"
                          placeholder="Başlığa göre arayın..."
                          value={search}
                          style={{ borderTopRightRadius: 0, borderBottomRightRadius: 0 }}
                          onChange={(e) => setSearch(e.target.value)}
                        />
                        <Button
                          className="bg-black"
                          type="submit"
                          style={{ borderTopLeftRadius: 0, borderBottomLeftRadius: 0 }}
                        >
                          Ara
                          <i className="fa fa-search"></i>
                        </Button>
                      </form>
                    </div>
                    <div className="container-fluid">
                      <div className="row">
                        <div className="table-scroll col-12">
                          <Table striped bordered hover>
                            <thead>
                              <tr className="text-bold text-center">
                           <th>Tamamlanan proje no</th>   <th>Görsel</th>
                                
                                <th>Tamamlanan proje adı</th>
                                <th>Başlama Tarihi</th>
                                <th>Bitiş Tatihi</th>
                                <th>Tamamlanan proje açıklaması</th>
                                <th>Seçenekler</th>
                              </tr>
                            </thead>
                            <tbody>
                              {tempProducts.length === 0 ? (
                                <p className="text-danger p-2">Tamamlanan proje bulunamadı</p>
                              ) : (
                                tempProducts.slice(0, 10)
                              )}
                            </tbody>
                          </Table>
                          {products ? (
                            <Paginator
                              len={products.length}
                              data={products}
                              setTempProducts={setTempProducts}
                              loadPage={loadPage}
                            />
                          ) : null}
                        </div>
                      </div>
                    </div>
                  </div>
                </CardBody>
              </Card>
            </Row>
          </Container>
        </section>
      </main>
    </Layout>
  );
};

export default TamamlananProjeler;
