import axios from "axios";
import { Navigate } from "react-router-dom";
export class FormApi {
  constructor(endpoint) {
    this.baseURL = process.env.REACT_APP_BASE_URL + endpoint;
    this.axiosObject = axios.create({
      baseURL: this.baseURL,
      headers: {
        "Content-Type": "multipart/form-data",
        Authorization: `Bearer ${localStorage.getItem("asdf")}`,
      },
    });
  }

  async getPage() {
    try {
      const item = await this.axiosObject.get("/", {
        auth: {
          username: process.env.REACT_APP_USERNAME,
          password: process.env.REACT_APP_PASSWORD,
        },
      });
      return item.data;
    } catch (error) {
      if (error.response.status === 500 || error.response.status === 401)
        window.location.href = "/";
    }
  }

  async addPage(data) {
    const page = await this.axiosObject.post("/", data);
    return page;
  }

  async updatePage(data) {
    const page = await this.axiosObject.patch("/", data);
    return page;
  }

  async deletePage(data) {
    const page = await this.axiosObject.delete(`/`, { data });
    return page;
  }

  async verifyJwt(data) {
    const res = this.axiosObject.post("/", { data });
    return res;
  }
  async deleteImage(data) {
    const image = await this.axiosObject.delete(`/`, { data });
    return image;
  }
}
