import React, { useState, useEffect } from "react";
import { Container, Card, CardTitle, CardBody, Row, Button, Table, Input } from "reactstrap";
import { useNavigate } from "react-router-dom";
import alertify from "alertifyjs";

import Layout from "../../Layout";

import Footer from "../../Layout/Footer";
import ReferencesTR from "./ReferencesTR";
import ReferencesEN from "./ReferencesEN";



const ReferencesMain = () => {


  return (
    <Layout>
      <main className="home">
        <Container>
          <div className="h1">Referanslar</div>
        </Container>

    <ReferencesTR/>
    <ReferencesEN/>
       <Footer/>
      </main>
    </Layout>
  );
};

export default ReferencesMain;
