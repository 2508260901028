import { useState, useEffect } from "react";
import { Form, FormGroup, Label, Input, Button } from "reactstrap";
import { Container, Card, CardTitle, CardBody, Row, Table } from "reactstrap";
import Layout from "../../Layout";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import alertify from "alertifyjs";
import { useLocation } from "react-router-dom";
import { FormApi } from "../../api/form_api";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const endpoint = "referencesTr";
function FormIndexRefTR() {
  const formApi = new FormApi(endpoint);
  const location = useLocation();

  const [form, setForm] = useState({
    refTrNo: "",
    icerik: "",
    baslik: "",
    sehir:""
 
  });

  const [validation, setValidation] = useState({
    refTrNo: "",
    icerik: "",
    baslik: "",
    sehir:""

  });
  useEffect(() => {
    if (location.state)
      Object.entries(location.state.product).forEach(([key, value]) => {
        onChangeHandler(key, value);
        setValidation((prevState) => ({ ...prevState}));
      });
  }, []);
  const validator = (key, value) => {
    if (value.length === 0 || value === "--" || value === "F") {
      setValidation((prevState) => ({ ...prevState, [key]: "F" }));
      return true;
    } else setValidation((prevState) => ({ ...prevState, [key]: "T" }));
  };

  const onChangeHandler = (key, value) => {
    setForm((prevState) => ({ ...prevState, [key]: value }));
    validator(key, value);
  };

  const onSubmitHandler = async (e) => {
    e.preventDefault();

    let isError = false;
    Object.entries(validation).forEach(([key, value]) => {
      if (validator(key, value)) isError = true;
    });

    let formData = new FormData();
    formData.append("baslik", form.baslik);
    formData.append("refTrNo", form.refTrNo);
    formData.append("icerik", form.icerik);
    formData.append("sehir", form.sehir);
    try {
      const rst = await formApi.addPage(formData);
      toast.success(
        "Referans(tr) eklendi..",
        ((window.location.href = "/referanslar"), 5000),
        2.5
      );
    } catch (e) {
      toast.error("Referans(tr) eklenemedi..", 2.5);
    }
  };
  const onUpdateHandler = async (e) => {
    e.preventDefault();
    let isError = false;
    Object.entries(validation).forEach(([key, value]) => {
      if (validator(key, value)) isError = true;
    });

    if (isError) {
      toast.error("Düzenlemeler yapılamadı..", 2.5);

      return;
    } else {
      let formData = new FormData();
      formData.append("_id", form._id);
      formData.append("baslik", form.baslik);
      formData.append("refTrNo", form.refTrNo);
      formData.append("icerik", form.icerik);
      formData.append("sehir", form.sehir);
      try {
        const rst = await formApi.updatePage(formData);
        toast.success(
          "Düzenlemeler yapıldı..",
          ((window.location.href = "/referanslar"), 1000),
          2.5
        );
      } catch (e) {
        toast.error("Düzenlemeler yapılamadı..", 1000);
      }
    }
  };


  return (
    <Layout>
        <ToastContainer/>
      <main className="home">
        <Container>
          <div className="h1">Referans Ekleme(tr)</div>
        </Container>

        <section id="section-one">
          <Container>
            <Row>
              <Card>
                <CardBody>
                 
                  <br />
                  <div className="container mt-3 pb-5">
      <h4>Referans Ekleme Paneli(tr)</h4>
      <hr />

      <Form onSubmit={onSubmitHandler}>
        

      

        <br />
        <div className="row col-12">
          <div className="col-12">
            <FormGroup>
              <Label for="refTrNo">
                <b>Referans Numarası</b>
              </Label>
              <Input
                id="refTrNo"
                name="refTrNo"
                placeholder="Lütfen referans numarasını giriniz..."
                type="number"
                valid={validation.refTrNo === "T"}
                invalid={validation.refTrNo === "F"}
                onChange={(e) => onChangeHandler(e.target.name, e.target.value)}
                value={form.refTrNo}
                min={0}
              />
         
            </FormGroup>
            <FormGroup>
              <Label for="sehir">
                <b>Referansın bulunduğu Şehir</b>
              </Label>
              <Input
                id="sehir"
                name="sehir"
                placeholder="Lütfen referansın bulunduğu şehri giriniz..."
                type="text"
                onChange={(e) => onChangeHandler(e.target.name, e.target.value)}
                value={form.sehir}
                valid={validation.sehir === "T"}
                invalid={validation.sehir === "F"}
              />
           
            </FormGroup>
            <FormGroup>
              <Label for="baslik">
                <b>Referans Adı</b>
              </Label>
              <Input
                id="baslik"
                name="baslik"
                placeholder="Lütfen referans adını giriniz..."
                type="text"
                onChange={(e) => onChangeHandler(e.target.name, e.target.value)}
                value={form.baslik}
                valid={validation.baslik === "T"}
                invalid={validation.baslik === "F"}
              />
           
            </FormGroup>
          </div>
        </div>

        <Label for="info">
          <b>Açıklama(tr)</b>
        </Label>
        <CKEditor
          name="icerik"
          editor={ClassicEditor}
          onReady={(editor) => {
            if (form.icerik !== "") editor.setData(form.icerik);
          }}
          onChange={(event, editor) =>
            onChangeHandler("icerik", editor.getData())
          }
        />
        {validation.icerik === "F" && (
          <div className="val-message">Bu alan boş bırakılamaz.</div>
        )}

        <br />
        <Button color="dark" type="submit" onClick={(e) => onSubmitHandler(e)}>
          Ekle
        </Button>
        {"  "}
        <Button
          type="submit"
          style={{
            background: "white",
            color: "black",
          }}
          onClick={(e) => onUpdateHandler(e)}
        >
          Güncelle
        </Button>
      </Form>
    </div>
                
                </CardBody>
              </Card>
            </Row>
          </Container>
        </section>
      </main>
    </Layout>
  );
}

export default FormIndexRefTR;
